<template>
  <ion-page>
    <ion-content
      id="main-content"
      :fullscreen="true"
      :class="isDark ? 'dark-theme' : ''"
    >
      <div v-if="loading" class="spinner-container">
        <ion-spinner color="primary" name="lines"></ion-spinner>
      </div>
      <div v-else>
      <ion-grid v-if="services.length > 0" >
        <div class="header">
          <h1>Check-In</h1>
          <span class="subtitle"
            >Select the event below you’re checking into:</span
          >
          </div>
          <ion-row justify-content-center align-items-center :class="services.length < 2 ? 'centredRow':''">
          <ion-col size="4" v-for="service in services" :key="service.id">
            <CardService :service="service" @select="onServiceSelect" :id="service.id"/>
          </ion-col>
        </ion-row>      
      </ion-grid>
       <div v-else class="emptyState">
          <h1>No Services Today</h1>

          <Button :data-cy="'newService'" @click="onCreateNewService" variant="secondary" text="Create new service" />

          <NewServiceModal :currentOrganizationId="user.currentOrganizationId" :locationId="location.id" ref="new-service-modal"/>

          <Button v-if="locations.length > 1" @click="$router.push({ name: 'Locations' })" variant="secondary" text="Go Back">
            <template #icon>
              <Arrow style="transform: rotate(180deg);"/>
            </template>
          </Button>
        </div>
      </div>  
    </ion-content>
  </ion-page>
</template>

<script>
import ViewMixin from '@/mixins/views';
import { mapActions, mapState, mapMutations } from 'vuex';
import { IonSpinner, IonPage, IonContent, toastController } from '@ionic/vue';

import CardService from '@/components/services/CardService';
import Button from '@/components/shared/buttons/Button.vue';
import Arrow from '@/components/shared/icons/Arrow.vue';
import NewServiceModal from '@/components/services/NewServiceModal';

export default {
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    CardService,
    Button,
    Arrow,
    NewServiceModal
  },
  
  mixins:[ViewMixin],

  data() {
    return {
      loading: false,
    };
  },

  ionViewDidEnter(){
    this.incrementComponentKeyCounter()
  },

  computed: {
    isDark() {
      return this.theme == 'dark';
    },

    ...mapState({
      location: (state) => state.locations.current,
      locations: (state) => state.locations.items,
      theme: (state) => state.theme,
      services: (state) => state.services.items,
      user: (state) => state.auth.user,
    }),
  },

  async ionViewWillEnter() {
    if (! this.location) {
      this.$router.push({name: 'Locations'});
    }

    try {
      this.loading = true;
      await this.getServices({
        locationId: this.location.id,
        organizationId: this.user.currentOrganizationId,
      });
    } catch (error) {
      const toast = await toastController.create({
        message: 'Server error',
        duration: 2000,
      });
      toast.present();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions('services', {
      getServices: 'get',
      showService: 'show',
    }),

    ...mapMutations({
      setCurrentService: 'services/SET_CURRENT',
    }),

    onServiceSelect(service) {
      this.setCurrentService(service);
      this.$router.push({
        name: 'LocationServiceSearch',
        params: { locationId: this.location.id, serviceId: service.id },
      });
    },

    onCreateNewService() {
      this.$refs['new-service-modal'].showModal();
    }
  },
};
</script>

<style scoped lang="scss">
ion-content {
  --overflow: scroll;
  ion-grid {
    overflow: hidden;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .centredRow{
       display: flex;
    justify-content: center;
    }
    ion-row {
      margin-top: 200px;
      width: 795px;
    }

    @media (min-width: 1024px){
      ion-row {
        width: 850px!important;
      }
    }

    .header{
      padding-top: 60px;
      padding-bottom: 20px;
      margin: -2%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: fixed;
      z-index: 10;
      background: #EFEFF2;
    }
    
  }
  .emptyState{
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      h1{
        margin-bottom: 10px;
        font-size: 24px;
      }
    }
  .spinner-container {
    height: 100vh;
    width: 100%;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  ion-content {
    ion-grid {
      ion-row {
        width: 750px;
      }
    }
  }
}
</style>
