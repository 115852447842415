<template>
  <Modal v-if="show" @close="show = false">
    <template #content>
      <div class="new-service">
        <h1>Create service</h1>

        <Form ref="serviceForm" v-slot="{ meta }">
          <Field
            v-slot="{ errors, field }"
            v-model="form.name"
            as="div"
            vid="name"
            name="Service Name"
            rules="required|min:1"
          >
            <Input
              v-bind="field"
              label="Service Name"
              type="text"
              placeholder="Service"
              cy-name="input-name"
              :required="true"
              :error="errors[0] || null"
              :capitalizeWords="true"
            />
          </Field>

          <Field
            v-slot="{ field }"
            v-model="form.day_of_week"
            as="div"
            vid="day_of_week"
            name="Day of week"
            rules="required"
          >
            <SelectInput
              v-bind="field"
              :options="dayOfWeekOptions"
              :required="true"
              placeholder="Not specified"
              label="Day of week"
              cy-name="input-day-of-week"
            />
          </Field>

          <Field
            v-slot="{ errors, field }"
            v-model="form.start_time"
            as="div"
            vid="start_time"
            name="Start Time"
            rules="required"
          >
            <DatePicker
              v-bind="field"
              :required="true"
              label="Start Time"
              cy-name="input-start-time"
              picker-format="HH:mm"
              display-format="HH:mm"
              :error="errors[0] || null"
            />
          </Field>

          <Field
            v-slot="{ errors, field }"
            v-model="form.end_time"
            as="div"
            vid="end_time"
            name="End Time"
            rules="required"
          >
            <DatePicker
              v-bind="field"
              :required="true"
              label="End Time"
              cy-name="input-end-time"
              picker-format="HH:mm"
              display-format="HH:mm"
              :error="errors[0] || null"
            />
          </Field>

          <div>
            <Button
              text="Save Service"
              button-type="submit"
              :loading="processing"
              :disabled="processing || !meta.valid"
              data-cy="service-submit-button"
              @click="onSubmit"
            />
          </div>
        </Form>
      </div>
    </template>

    <template #button>
      <span></span>
    </template>
  </Modal>
</template>

<script>
  import { Form, Field } from 'vee-validate';

  import Modal from '@/components/shared/modals/Modal';
  import Input from '@/components/shared/inputs/Input';
  import SelectInput from '@/components/shared/inputs/SelectInput';
  import DatePicker from '@/components/shared/inputs/DatePicker';
  import Button from '@/components/shared/buttons/Button';
  import { mapActions } from 'vuex';

  export default {
    components: {
      Modal,
      Form,
      Field,
      Input,
      SelectInput,
      DatePicker,
      Button,
    },

    props: {
      locationId: {
        type: Number,
        required: true
      },
      currentOrganizationId: {
        type: Number,
        required: true
      },
    },

    data: () => ({
      show: false,
      processing: false,
      form: {
        name: '',
        day_of_week: null,
        start_time: null,
        end_time: null,
      },
      dayOfWeekOptions: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
      ],
    }),

    mounted: () => {},

    methods: {
      ...mapActions({
        storeService: `services/store`,
        getServices: `services/get`,
      }),

      showModal: function () {
        this.show = true;
      },

      onSubmit: async function () {
          try {
            this.processing = true;
            await this.storeService(this.form);
            await this.getServices(({
              locationId: this.locationId,
              organizationId: this.currentOrganizationId,
            }));
          } catch (ex) {
              console.log(ex);
          } finally {
              this.processing = false;
              this.show = false;
          }
      },
    },
  };
</script>

<style scoped lang="scss">
  .new-service {
    h1 {
      font-size: 28px;
      line-height: 1;
      margin-top: 0;
    }

    form {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 512px;
      overflow: auto;

      > div {
        margin-top: 20px;
      }
    }
  }
</style>
