<template>
  <ion-card :button="true" @click="$emit('select', service)">
    <ion-card-header>
        <ion-card-title>{{ service.name }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <span>{{ serviceTime }}</span>
        <div class="btn-container">
        <Button :text="'Check in →'" @click="$emit('select', service)" variant="primary" size="medium" />
        </div
    ></ion-card-content>
    </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/vue';
import Button from '@/components/shared/buttons/Button';

export default {
    name:"CardService",

    components: { IonCard, IonCardContent, IonCardHeader,IonCardTitle ,Button},

     props: {
      service: {
        type: Array,
        required: true
      }
    },

    computed: {
        serviceHour() {
            const time = this.service.start_time.split(':');

            return time[0];
        },

        serviceMinute() {
            const time = this.service.start_time.split(':');

            return time[1];
        },

        serviceTime() {
            return this.$dayjs()
                .set('hour', this.serviceHour)
                .set('minute', this.serviceMinute)
                .format('h:mm a');
        },
    }
}
</script>

<style scoped lang="scss">
ion-card {
    display: flex;
    justify-content: center;
    align-items: center;
    --background:white;
    height: 170px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    ion-card-header{
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        ion-card-title {
            text-align: center;
            color:#181F36;
            font-size: 17px;
            font-weight: bold;
         }
    }
    ion-card-content{
        padding-inline: 10px;
        padding-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span{
            margin-bottom: 10px;
        }
    }
}
</style>
